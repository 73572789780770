import React, { useState, useContext, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Navigation from '../Navigation/Navigation'

import SiteLogo2019 from '../../images/logo-2019.png'
import SiteLogo2020 from '../../images/logo-2020.png'
import SiteLogo2021 from '../../images/logo-2021.png'
import SiteLogo2022 from '../../images/logo-2022.png'
import SiteLogo2023 from '../../images/logo-2023.png'
import SiteLogo2024 from '../../images/logo-2024.png'

import { MEASUREMENTS } from '../../helpers/constants'

import { ArchiveContext } from '../../context/ArchiveContext';

export default function Header () {

	const queryData = useStaticQuery(graphql`
		query NavQuery {
			strapi {
				navigation {
					navItems {
						id
						label
						url
						hasArchive
						subNavItems {
							id
							label
							url
							hasArchive
						}
					}
				}
			}
		}
	`)

	const data = queryData.strapi.navigation.navItems
	const navItemsLeft = data.slice( 0, 3 )
	const navItemsRight = data.slice( 3 )

	const RESIZE_DEBOUNCE = 150;
	const headerRef = useRef()
	const getWidth = () => headerRef.current.offsetWidth;

	const [ menuToggled, toggleMenu ] = useState(false)
	const [ subNavToggled, toggleSubNav ] = useState(false)
	const [ width, setWidth ] = useState( 0 )

	const { year } = useContext( ArchiveContext );

	useEffect( () => {
		if ( headerRef ) {
			setWidth( headerRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] )

	const renderLogo = year => {

		switch ( year ) {
			case '/2019':
				return <img src={ SiteLogo2019 } className="header__logo" alt="Eye Sculpture Trail Logo 2019" />
			case '/2020':
				return <img src={ SiteLogo2020 } className="header__logo" alt="Eye Sculpture Trail Logo 2020" />
			case '/2021':
				return <img src={ SiteLogo2021 } className="header__logo" alt="Eye Sculpture Trail Logo 2021" />
			case '/2022':
				return <img src={ SiteLogo2022 } className="header__logo" alt="Eye Sculpture Trail Logo 2022" />
			case '/2023':
				return <img src={ SiteLogo2023 } className="header__logo" alt="Eye Sculpture Trail Logo 2023" />
			case '/2024':
				return <img src={ SiteLogo2024 } className="header__logo" alt="Eye Sculpture Trail Logo 2024" />
			default:
				return <img src={ SiteLogo2024 } className="header__logo" alt="Eye Sculpture Trail Logo 2024" />
		}
	}

	return (
		<header className="header" ref={ headerRef }>

			<div className="header__container">

				<button className="header__mobile-menu-toggle u-show-tablet" onClick={ () =>toggleMenu( !menuToggled ) }>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

				{  width > MEASUREMENTS.TABLET ? (
					<>
						<div className="header__navigation-container">
							<Navigation menuOnLeft={ true } toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ navItemsLeft } />
						</div>



						<Link to={`${ year ? year : '/' }`} className="header__title-link">
							{ renderLogo( year ) }
						</Link>

						<div className="header__navigation-container">
							<Navigation menuOnLeft={ false } toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ navItemsRight } />
						</div>
					</>
				) : (
					<>
						<Link to={`${ year ? year : '/' }`} className="header__title-link">
							{ renderLogo( year ) }
						</Link>
						<div className="header__navigation-container">
							<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ data } />
						</div>
					</>
				)}
			</div>
		</header>
	)
}
