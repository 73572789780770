// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-minis-js": () => import("./../../../src/templates/minis.js" /* webpackChunkName: "component---src-templates-minis-js" */),
  "component---src-templates-sculpture-js": () => import("./../../../src/templates/sculpture.js" /* webpackChunkName: "component---src-templates-sculpture-js" */),
  "component---src-templates-sculptures-js": () => import("./../../../src/templates/sculptures.js" /* webpackChunkName: "component---src-templates-sculptures-js" */),
  "component---src-templates-sponsors-js": () => import("./../../../src/templates/sponsors.js" /* webpackChunkName: "component---src-templates-sponsors-js" */)
}

