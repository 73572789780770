import React from 'react';

import IconFacebook from '../../images/svgs/facebook-icon.svg'
import IconInstagram from '../../images/svgs/instagram-icon.svg'
import IconTwitter from '../../images/svgs/twitter-icon.svg'

export default function GetInvolved() {

	return (
		<div className="get-involved">
			<h3 className="get-involved__heading">Get Involved!</h3>
			<p className="get-involved__text">Please tag your photos on social media with #EyeSculptureTrail</p>
			<div className="get-involved__social-links">
				<a href="https://www.instagram.com/explore/tags/eyesculpturetrail/" className="get-involved__link" target="_blank" rel="noreferrer">
					<IconInstagram className="get-involved__icon" />
				</a>
				<a href="https://www.facebook.com/hashtag/eyesculpturetrail" className="get-involved__link" target="_blank" rel="noreferrer">
					<IconFacebook className="get-involved__icon" />
				</a>
				<a href="https://twitter.com/hashtag/eyesculpturetrail" className="get-involved__link" target="_blank" rel="noreferrer">
					<IconTwitter className="get-involved__icon" />
				</a>
			</div>
		</div>
	)
}