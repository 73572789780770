import React, { useEffect, useState, useContext } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import { MEASUREMENTS } from '../../helpers/constants'

import { ArchiveContext } from '../../context/ArchiveContext';

export default function Archive( props ) {

	const { year, updateYear } = useContext( ArchiveContext );

	const { toggleMenu, menuToggled, width } = props
	const [ active, setActive ] = useState( false )
	const [ firstClick, setFirstClick ] = useState( true )

	const isMobile = width < MEASUREMENTS.TABLET


	const queryData = useStaticQuery(graphql`
		query ArchiveQuery {
			strapi {
				artTrails(sort: "id:DESC") {
					trailYear
				}
			}
		}
	`)

	const data = queryData.strapi.artTrails

	useEffect(() => {
		setFirstClick( true )
		setActive( false )
	}, [ menuToggled ]) 

	const handleSubNavClick = ( e ) => {
		if ( !isMobile ) {
			return
		}

		if ( firstClick && isMobile ) {
			e.preventDefault()
			setFirstClick( false )
		} else {
			toggleMenu( false )
			setFirstClick( true )
		}
		setActive( !active )
	}

	const handleClick = e => {
		toggleMenu( false )

		if ( year !== `/${e}` ) {
			updateYear( e )
		}
	}

	return (
		<li className={`navigation__item navigation__item--has-dropdown ${active ? 'is-open': ''}`}>
			<div className="navigation__dropdown">
				<div className="navigation__link-container">
					<button
						className="navigation__link"
						onClick={ (e) => handleSubNavClick( e )}>
						Archive
					</button>
				</div>
				<ul className={`navigation__dropdown-options ${active ? 'is-open': ''}`}>
					{ data.map( ( subItem, index ) => (
						<li className="navigation__dropdown-option" key={ index }>
							<Link
								to={`/${ index ? subItem.trailYear : '' }`}
								className="navigation__link navigation__link--in-dropdown"
								activeClassName="active"
								onClick={() => handleClick( subItem.trailYear )}>
								{ subItem.trailYear }
							</Link>
						</li>
					) ) }
				</ul>
			</div>
		</li>
	)
}