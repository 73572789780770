import React from 'react'

import Layout from './src/components/Layout/Layout'
import CookiePolicy from './src/components/Cookie/CookiePolicy'

import ArchiveContextProvider from './src/context/ArchiveContext'

import './src/styles/toolkit.scss'

export const wrapRootElement = ({ element }) => (
	<ArchiveContextProvider>
		<Layout>
			{ element }
			<CookiePolicy />
		</Layout>
	</ArchiveContextProvider>
)