import React from 'react'
import { Link } from 'gatsby'

import Flowers from '../../images/flowers.png'

export default function Footer() {

	return (
		<footer className="footer" style={{ backgroundImage: `url(${Flowers})` }}>
			<div className="footer__wrapper">
				<div className="footer__content-container">
					<div className="footer__info-title">Eye Sculpture Trail</div>
					{/* <div className="footer__info">Helping you become the best verison of yourself</div> */}
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Contact Us</div>
					<ul className="footer__list">
						<li className="footer__list-item"><a className="footer__link" href="tel:01379678483">01379 678483</a></li>
						<li className="footer__list-item"><a className="footer__link" href="mailto:info@theblossomcharity.co.uk">info@theblossomcharity.co.uk</a></li>
						<li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/contact-us/">Contact Us</a></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Who We Are</div>
					<ul className="footer__list">
						<li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/charitable-purpose-of-objects">Charitable Purpose of Objects</a></li>
						<li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/our-trustees">Our Trustees</a></li>
						{/* <li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/our-patron">Our Patron</a></li> */}
						<li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/about-us#team-members">Our Team</a></li>
						<li className="footer__list-item"><a className="footer__link" href="https://www.theblossomcharity.co.uk/about-us#wider-family">Our Wider Family</a></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">The Trail</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/artists">Artists</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/sculptures">Sculptures</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/sponsors">Sponsors</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/the-trail">The Trail</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">The Technical Stuff</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/cookie-policy">Cookie Policy</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/terms-and-privacy">Terms &amp; Privacy Policy</Link></li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
