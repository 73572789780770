import React, { useContext } from 'react'
import { Link } from 'gatsby'

import SubNavigation from './SubNavigation'
import Archive from '../Archive/Archive'

import { ArchiveContext } from '../../context/ArchiveContext';

export default function Navigation( props ) {

	const { menuOnLeft, toggleMenu, menuToggled, toggleSubNav, width, data } = props

	const { year } = useContext( ArchiveContext )

	return (
		<div className={`navigation ${ menuOnLeft ? 'navigation--left' : '' } ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( navItem => (
							<React.Fragment key={ navItem.id }>
								{ navItem.subNavItems.length ? (
									<SubNavigation navItem={ navItem } toggleMenu={ toggleMenu } menuToggled={ menuToggled } toggleSubNav={ toggleSubNav } width={ width } />
								) : (
									<li className="navigation__item">
										<Link
											to={`${ navItem.hasArchive ? year : '' }${ navItem.url }`}
											className="navigation__link"
											activeClassName="active"
											partiallyActive={ true }
											onClick={() => toggleMenu( false ) }>
											{ navItem.label }
										</Link>
									</li>
								) }
							</React.Fragment>
						) ) }
						{ !menuOnLeft &&
							<Archive toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />
						}
					</ul>
				</div>
			</nav>
		</div>
	)
}