import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const ArchiveContext = createContext( null );

export default ( { children } ) => {

	const queryData = useStaticQuery(graphql`
		query ArchiveContextQuery {
			strapi {
				artTrails(sort: "id:DESC") {
					trailYear
				}
			}
		}
	`)

	const data = queryData.strapi.artTrails

	const getUrl = () => {

		if ( typeof window === 'undefined' ) {
			return [];
		}
		
		const url = window.location.pathname.split('/')
		// on 1 as first part is /
		const year = url[ 1 ]

		const foundYear = data.find( (node, index) => node.trailYear === year && index !== 0 ) ? `/${ year }` : ''
		return foundYear
	}

	const fetchYear = getUrl()
	const [ year, setYear ] = useState( fetchYear )

	const updateYear = updatedYear => {
		// checking whether it is the current site with index === 0
		const foundYear = data.find( ( node, index ) => node.trailYear === updatedYear && index !== 0 ) ? `/${ updatedYear }` : ''
		setYear( foundYear )

		document.body.className = `t-${updatedYear}`
	}

	// Updates theming of the year of page refresh
	useEffect(() => {
		updateYear( year.split( '/' )[ 1 ] )
	}, [])

	return (
		<ArchiveContext.Provider value={{ year, updateYear }}>
			{ children }
		</ArchiveContext.Provider>
	)
}